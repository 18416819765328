<script>
import { mapActions, mapMutations, mapGetters } from "vuex";

import Footer from "@/layouts/Footer.vue";
import NavBarVotacion from "@/components/NavBarVotacion.vue";

export default {
	components: {
		Footer,
		NavBarVotacion,
	},
	data() {
		return {
			totalPages: null,
			currentPage: null,
			profile: {},
			users: [],
		};
	},
	created() {
		this.currentPage = 1;
		this.loadProfile();
	},
	computed: {
		...mapGetters({
			isLoading: "loading/isLoading",

			//READ_PROFILE
			readProfile: "auth/readUser",

			//READ_USERS
			readVotesCount: "getVotesCount/readVotes",
			readUsers: "getUsers/readUsers",
			readTotalPages: "getUsers/readTotalPages",

			//READ_ERRORS
			getVotesCountError: "getVotesCount/readError",
			getProfileError: "auth/readError",
			getUsersError: "getUsers/readError",
		}),
	},
	methods: {
		...mapMutations({
			setLoading: "loading/SET_LOADING",

			//SEARCH_USER_FILTER
			setUsersFilter: "getUsers/SET_FILTER",
			setUsersPagination: "getUsers/SET_PAGINATION",

			//SET_USER_ID
			setUserId: "getUsers/SET_ID",
			setVotesCountId: "getVotesCount/SET_ID",

			//SET_ERRORS
			setProfileError: "auth/SET_ERROR",
			setVotesCountError: "getVotesCount/SET_ERROR",
			setUsersError: "getUsers/SET_ERROR",
		}),
		...mapActions({
			//SEARCH_USER
			getUsers: "getUsers/getUsers",

			//USERS
			getVotesCount: "getVotesCount/getVotesCount",
		}),
		loadProfile() {
			this.clearErrors();
			this.profile = this.readProfile;
			this.setUsersPagination(null);
			this.loadUsers();
		},
		loadPage() {
			if (this.currentPage == this.totalPages) {
				return;
			} else {
				this.currentPage++;
				this.loadUsers();
			}
		},
		loadUsers() {
			this.clearErrors();
			this.setUserId(null);
			this.setUsersPagination({
				page: this.currentPage,
				size: "18",
			});
			this.setUsersFilter({
				name: "region",
				id: this.profile.region_id,
			});
			this.getUsers().then((result) => {
				if (result && this.readUsers.length > 0) {
					this.totalPages = Math.ceil(this.readTotalPages / 18);
					this.filterUsers(this.readUsers);
				}
			});
		},
		filterUsers(payload) {
			payload.filter((u, key) => {
				const pageKey = [(this.currentPage - 1) * 18 + key];
				if (this.currentPage == 1) {
					this.users = payload;
					this.pageKey = key;
				} else {
					this.$set(this.users, pageKey, u);
				}
				this.setVotesCountId(u.id);
				this.getVotesCount().then((response) => {
					if (response) {
						this.$set(
							this.users[pageKey],
							"votes",
							this.readVotesCount.receiver_votes
						);
					}
				});
			});
		},
		scroll() {
			const usersList = this.$refs.usersList;

			if (
				usersList?.scrollTop >
				(usersList?.scrollHeight - usersList?.offsetHeight) * 0.9
			) {
				this.loadPage("next");
			}
		},
		clearErrors() {
			this.setProfileError(null);
			this.setVotesCountError(null);
			this.setUsersError(null);
		},
	},
};
</script>
<template>
	<div
		class="stats relative h-full flex flex-col justify-center items-center"
	>
		<div
			class="my-2 flex flex-col overlay bg-white mx-auto overflow-hidden"
		>
			<div
				ref="usersList"
				@scroll="scroll"
				v-if="!isLoading && users.length > 0"
				class="users-list grid gap-2 grid-cols-3 overflow-y-scroll py-6 px-3"
			>
				<div
					v-for="u in users"
					:key="u.id"
					class="flex flex-col justify-between h-32 items-center pt-1 rounded-xl border-2 border-gray shadow-md overflow-hidden"
				>
					<span
						class="flex justify-center items-center icon_user rounded-full border mb-1 overflow-hidden"
					>
						<font-awesome-icon
							v-if="!u.photo"
							class="text-xl black"
							icon="fa-solid fa-user"
						/>

						<img
							v-else
							class="block w-full object-cover object-center flex-none"
							:src="u.photo"
							alt="Trofeo"
						/>
					</span>
					<div class="w-full text-center px-1">
						<span class="block text-sm truncate">{{
							!u.name || u.basic_info
								? `${u.first_name} ${u.last_name}`
								: u.name
						}}</span>
					</div>
					<div class="w-full text-center bg-green">
						<span
							class="w-4 h-4 rounded-full inline-block text-xs b green text-white bg-white"
							>{{ u.votes }}</span
						>
					</div>
				</div>
			</div>
			<div v-else class="text-md m-auto font-md b blue px-6 text-center">
				No se encontraron los datos de los Colaboradores
			</div>
			<div class="mt-auto">
				<nav-bar-votacion :active="this.$route.name"></nav-bar-votacion>
				<Footer class="static"></Footer>
			</div>
		</div>
	</div>
</template>
<style lang="scss">
.overlay {
	height: calc(100% - 1rem);
}

.icon_user {
	border: get-vh(6px) solid #d6e2f0;
	width: get-vh(150px);
	height: get-vh(150px);
	box-shadow: 0px 0px 5px 2px rgba($color: #d6e2f0, $alpha: 0.8);
}
</style>
